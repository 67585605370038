import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import HeroNewApp from 'src/pages/us/newapp/sections/HeroNewApp/_index'
import VejaComoEsimples from 'src/pages/us/newapp/sections/veja-como-e-simples/_index'
import JaCriouSuaConta from 'src/pages/us/newapp/sections/ja-criou-sua-conta/_index'
import DisclamerUsend from 'src/pages/us/newapp/sections/disclamer/_index'
import AppSeraDesativado from 'src/pages/us/newapp/sections/app-sera-desativado/_index'
import NossaMelhorTaxa from 'src/pages/us/newapp/sections/nossa-melhor-taxa/_index'

import pageContext from './pageContext.json'

const Hero = () => {
  const lang = 'en'

  return (
    <Layout pageContext={pageContext}>
      <HeroNewApp lang={lang} />
      <VejaComoEsimples lang={lang} />
      <JaCriouSuaConta lang={lang} />
      <DisclamerUsend lang={lang} />
      <AppSeraDesativado lang={lang} />
      <NossaMelhorTaxa lang={lang} />
    </Layout>
  )
}

export default Hero
